.services-container {
  max-width: 100%;
  min-height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 920px) {
  .services-container li {
    font-size: 18px;
  }
}

.border {
  border-bottom: 1px solid darkslategray;
}

.services-container h1,
h3,
p,
li {
  color: darkslategray;
}

.offer {
  display: flex;
  /* justify-content: space-between; */
}

@media (max-width: 920px) {
  .offer {
    flex-direction: column-reverse;
  }
}

.offer-bottom {
  display: flex;
  /* justify-content: space-between; */
}

@media (max-width: 920px) {
  .offer-bottom {
    flex-direction: column-reverse;
  }
}

.offerImage {
  width: auto;
  padding-right: 5%;
}

@media only screen and (max-width: 920px) {
  .offerImage {
    display: none;
  }
}

.services-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5%;
}

@media (max-width: 920px) {
  .services-2 li {
    font-size: 18px;
  }
}

.services-2 h1 {
  margin: 0;
}

.services-2 p {
  margin-bottom: 0;
}

.services-2 ul {
  margin-bottom: 0;
}

@media only screen and (max-width: 1100px) {
  .services-2 {
    width: auto;
  }
}

@media only screen and (max-width: 1100px) {
  .services-about-button-container {
    display: flex;
    justify-content: center;
    padding-left: 0;
  }
}

.services-2 li {
  font-size: 20px;
}

.services-1 li {
  font-size: 20px;
}

.small-text {
  font-size: 14px;
}

.service-details {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
}

.service-details h3 {
  font-size: 20px;
}

.service-details p {
  margin-bottom: 0;
}

.service-details h1 {
  margin: 0;
}

.services-1-container {
  display: flex;
  flex-wrap: wrap;
}

.services-contact-button {
  padding: 15px;
  cursor: pointer;
  letter-spacing: 1px;
  margin-right: 5px;
  background-color: #949189;
  width: fit-content;
  text-align: center;
  border-radius: 5px;
  margin-top: 25px;
}

.services-contact-button:hover {
  background-color: #ffcc00;
}

@media (max-width: 920px) {
  .services-contact-button {
    margin-bottom: 20px;
  }
}

.services-banner {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  background-color: #cca300;
  text-align: center;
  height: 200px;
  justify-content: center;
}
