.footer-container {
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  min-height: 200px;
}

.footer-container p {
  color: darkslategray;
}

.footer-top {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding: 20px 0px;
  justify-content: space-evenly;
  width: 100%;
}

@media only screen and (max-width: 1100px) {
  .footer-top {
    flex-direction: column;
  }
}

a {
  color: #fff;
}

.top-footer-link {
  margin-right: 25px;
  min-width: 100px;
}

.footer-bottom {
  text-align: center;
}

.footer-contact-details {
  display: flex;
  flex-direction: column;
}

.footer-contact-details img {
  color: white;
}

.footer-contact-detail {
  display: flex;
}

.city-guilds-logo {
  height: 50px;
  width: 80px;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.more-margin-top {
  margin-top: 13px;
}

.footer-contact-icons {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  align-self: center;
  margin-top: 20px;
}

.no-margin-bottom {
  margin-bottom: 0px;
}
