.slider-container {
  display: flex;
  justify-content: center;
}

.slider {
  position: relative;
}

.slider-image {
  width: 500px;
  height: 500px;
  border-radius: 10px;
}

@media only screen and (max-width: 650px) {
  .slider-image {
  width: 450px;
  height: 450px;
  }
}

@media only screen and (max-width: 450px) {
  .slider-image {
  width: 300px;
  height: 300px;
  }
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: -70px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: -70px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}