.contact-container {
  max-width: 100%;
  min-height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.contact-content-top {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 20px;
}

.contact-us1 {
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  padding-right: 5%;
  color: darkslategray;
  padding-left: 40px;
}

@media only screen and (max-width: 1100px) {
  .contact-us1 {
    width: auto;
  }
}

.contact-us1 h1 {
  margin: 0;
}

.contact-us1 p {
  margin-bottom: 0;
}

.contactImage2 {
  margin-top: 20px;
  padding-right: 40px;
}

@media only screen and (min-width: 1100px) {
  .contactImage2 {
    display: none;
    padding-left: 0px;
    margin-top: 20px;
  }
}

.contactImage {
  padding-left: 10%;
}

@media only screen and (max-width: 1100px) {
  .contactImage {
    display: none;
  }
}

.contact-bottom-title {
  text-align: center;
}

.details-half {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.contact-icons {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  align-self: center;
}

.detail {
  display: flex;
  padding-right: 10px;
}

.detail2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detail2 a {
  color: black;
}

.less-margin-title {
  margin-bottom: 0;
}

.details-right p {
  margin: 0;
}

.details-right a {
  color: black;
}

.homepage-banner-top-contact {
  margin-top: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  background-color: #cca300;
  text-align: center;
  height: 215px;
}

.homepage-banner-top-contact p {
  color: #fff;
  font-size: 18px;
}

@media only screen and (max-width: 736px) {
  .homepage-banner-top-contact {
    padding-top: 20px;
  }
}

.comma {
  width: 24%;
}

.homepage-banner-text {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.roller-brush-image {
  width: 400px;
  height: 400px;
}

@media only screen and (max-width: 1100px) {
  .roller-brush-image {
    height: 300px;
    width: 300px;
  }
}

@media only screen and (max-width: 1100px) {
  .roller-brush-image {
    height: 200px;
    width: 300px;
  }
}

@media only screen and (max-width: 1100px) {
  .contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 1100px) {
  .contact-details-container {
    display: flex;
    justify-content: space-around;
  }
}
