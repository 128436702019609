.container {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding: 0px 40px;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  .container {
    width: 100vh;
  }
}

p {
  font-size: 20px;
}

a {
  text-decoration: none;
  color: black;
}

.container img {
  height: 200px;
}

.logo {
  width: 30%;
}

.links {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  width: 70%;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 15px;
}

@media only screen and (max-width: 1100px) {
  .links {
    justify-content: center;
  }
}

.header-button {
  padding: 15px;
  cursor: pointer;
  letter-spacing: 1px;
  margin-right: 5px;
  background-color: #949189;
  width: 100px;
  text-align: center;
  border-radius: 5px;
}

@media only screen and (max-width: 500px) {
  .header-button {
    width: 90px;
  }
}

.button1:hover {
  color: #ffcc00;
}

.button2:hover {
  color: #fff;
}

@media only screen and (max-width: 1100px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .container img {
    height: 150px;
  }
}
