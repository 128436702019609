.about-container {
  max-width: 100%;
  min-height: 100%;
  padding: 40px;
}

.about-container h1 {
  color: darkslategray;
}

.about-page {
  display: flex;
  justify-content: center;
}

@media (max-width: 920px) {
  .about-page {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.about-image {
  height: 400px;
  width: 600px;
}

.about-image2 {
  height: 200px;
  width: 300px;
  padding-right: 40px;
}

@media (min-width: 1100px) {
  .about-image2 {
    display: none;
  }
}

.about-page-image {
  padding-top: 25px;
  padding-right: 40px;
}

@media (max-width: 1100px) {
  .about-page-image {
    display: none;
  }
}

.about-page-button {
  padding: 15px;
  cursor: pointer;
  letter-spacing: 1px;
  margin-right: 5px;
  background-color: #949189;
  width: fit-content;
  text-align: center;
  border-radius: 5px;
  margin-top: 25px;
}

.about-page-button:hover {
  background-color: #ffcc00;
}

@media (max-width: 920px) {
  .about-page-button {
    margin-bottom: 20px;
  }
}

.homepage-banner-top {
  margin-top: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  background-color: #cca300;
  text-align: center;
  height: 200px;
}

.homepage-banner-top p {
  color: #fff;
}

.comma {
  width: 24%;
}

.homepage-banner-text {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.about-us-content {
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  padding-right: 5%;
}

.about-us-content p {
  margin-bottom: 0;
}

.about-us-content h1 {
  margin-bottom: 0;
}

@media (max-width: 1100px) {
  .about-us-content {
    width: auto;
    padding-right: 0;
  }
}

@media (max-width: 1100px) {
  .about-page-button-container {
    display: flex;
    align-items: center;
    padding-top: 50px;
    padding-left: 0;
  }
}
