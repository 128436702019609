.app-container {
  /* padding: 0px 50px 50px 50px; */
  background-color: #fff;
  box-sizing: border-box;
}

@media only screen and (max-width: 650px) {
  .app-container {
    width: 100vh;
  }
}