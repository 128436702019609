.gallery-page-container {
    max-width: 100%;
    min-height: 100%;
    padding: 40px;
    padding-top: 0;
    border-bottom: 1px solid darkslategray;
}

.gallery-page-container h1 {
  color: darkslategray
}

.check-trade-button {
  width: 29%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-right: 100px;
  padding-top: 50px;
}

@media only screen and (max-width: 1100px) {
  .check-trade-button {
    width: auto;
    padding: 0;
  }
}

.top-content-container {
  display: flex;
}

@media only screen and (max-width: 1100px) {
  .top-content-container {
      flex-direction: column;
      align-items: center;
  }
}

.top-content {
  display: flex;
  flex-direction: column;
}

.gallery-container {
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 40px;
}

@media only screen and (max-width: 1100px) {
  .gallery-container {
    padding-top: 15px;
  }
}

.image-container {
  padding-right: 20px;
  padding-bottom: 16px
}

.image {
  max-height: 400px;
  max-width: 400px;
}

.check-trade-image {
  max-height: 250px;
  max-width: 250px;
}

.reviews-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media only screen and (max-width: 1100px) {
  .reviews-button-container {
    margin: 20px 0;
  }
}


.reviews-button {
  padding: 15px;
  letter-spacing: 1px;
  background-color: #949189;
  width: fit-content;
  text-align: center;
  border-radius: 5px;
}

.reviews-button:hover {
  background-color:  #ffcc00; 
}

.top-content-wrapper {
  width: 90%
}

@media only screen and (max-width: 1100px) {
  .top-content-wrapper {
    width: auto;
  }
}

@media only screen and (max-width: 1100px) {
  .click-instruction {
    display: none;
  }
}

@media only screen and (min-width: 1101px) {
  .click-instruction2 {
    display: none;
  }
}

