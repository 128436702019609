.homeContainer {
  max-width: 100%;
  min-height: 100%;
  padding: 40px;
  padding-top: 70px;
}

.homeContainer2 {
  max-width: 100%;
  min-height: 100%;
  padding: 40px;
  border-bottom: 1px solid darkslategray;
  padding-top: 70px;
}

.homeContainer h1 {
  color: darkslategray;
}

.homeContainer2 h1 {
    color: darkslategray;
  }

.about {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  max-height: 350px;
  padding-right: 10px;
}

@media (max-width: 920px) {
    .about {
        justify-content: center;
    }
  }

@media (max-width: 920px) {
  .about img {
    height: 250px;
    width: 275px;
  }
}

.aboutImage {
  width: 45%;
  padding-left: 5%;
  display: flex;
  justify-content: flex-end;
  padding-right: 35px;  
}

@media only screen and (max-width: 1100px) {
  .aboutImage {
    padding-left: 0;
  }
}

.aboutImage2 {
  width: 45%;
  display: flex;
  padding-left: 60px;
  justify-content: flex-start;
}

@media (max-width: 1100px) {
  .aboutImage2 {
    padding-right: 0;
    padding-left: 35px;
  }
}

.about-us1 {
  display: flex;
  flex-direction: column;
  width: 36%;
  justify-content: center;
  padding-right: 10%;
  text-align: start;
}

.about-us1 h1 {
  margin: 0;
}

.about-us2 {
  display: flex;
  flex-direction: column;
  width: 36%;
  justify-content: center;
  padding-left: 5%;
  text-align: end;
}

.about-us2 h1 {
  margin: 0;
}

.about-button {
  padding: 15px;
  cursor: pointer;
  letter-spacing: 1px;
  margin-right: 5px;
  background-color: #949189;
  width: fit-content;
  text-align: center;
  border-radius: 5px;
}

.about-button:hover {
  background-color: #ffcc00;
}

.recent-work {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 20px;
  max-height: 350px;
  justify-content: center;
  padding-left: 8%;
}

.recommend {
  display: flex;
  margin-top: 40px;
  font-size: 24px;
}

.review {
  width: 50%;
  font-weight: bold;
  text-align: center;
  padding: 0 10%;
  font-size: 20px;
}

.review-name {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  font-weight: normal;
  font-size: 20px;
}

.banner {
  margin-top: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  background-color: #cca300;
  text-align: center;
  height: 200px;
  justify-content: center;
}

.homepage-banner-top {
  margin-top: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  background-color: #cca300;
  text-align: center;
  height: 200px;
}

.homepage-banner-top p {
  color: #fff;
}

@media (max-width: 920px) {
  .homepage-banner-top p {
    font-size: 18PX
  }
}

.comma {
  width: 24%;
}

.homepage-banner-text {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.header-image {
  background-image: url("../../images/paint-pots.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: darkslategray;
}

.contact-button {
  border-radius: 5px;
  background-color: #ffcc00;
  padding: 15px;
  cursor: pointer;
  letter-spacing: 1px;
  min-width: 115px;
  text-align: center;
  color: darkslategray;
}

.reviewer {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
}

.homepage-image {
  height: 300px;
  width: 400px;
}

@media (max-width: 920px) {
  .homepage-image {
    height: 250px;
    width: 275px;
  }
}

.content-end {
  display: flex;
  justify-content: flex-end;
}
